<script setup lang="ts">
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

const bookingWidgetStore = useBookingWidgetStore()
function toggleBookingModal() {
  bookingWidgetStore.toggleBookingModal('nav')
}
</script>

<template>
  <button @click="toggleBookingModal">
    <slot />
  </button>
</template>
